import { SES } from 'aws-sdk';

// AWS credentials from environment variables
const REACT_APP_AWS_SES_EMAIL_ADDRESS = process.env.REACT_APP_AWS_SES_EMAIL_ADDRESS as string;


// SES configuration
const ses = new SES({
  region: process.env.REACT_APP_AWS_REGION as string,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
});

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export const sendContactForm = async (formData: ContactFormData) => {
  const params = {
    Destination: {
      ToAddresses: ["jameslnew@gmail.com"], // Assuming this is the destination
    },
    Message: {
      Body: {
        Text: {
          Data: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
        },
      },
      Subject: {
        Data: 'New Contact Form Submission',
      },
    },
    Source: REACT_APP_AWS_SES_EMAIL_ADDRESS,  // Your email address
  };

  try {
    console.log('REACT_APP_AWS_SES_EMAIL_ADDRESS:', REACT_APP_AWS_SES_EMAIL_ADDRESS);
    await ses.sendEmail(params).promise();
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};
