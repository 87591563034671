import styles from './Education.module.css';

const OurFounder: React.FC = () => {
    return (
        <div className={styles.education}>
            <h1 className={styles.header}>Your Technological Education</h1>
            <div className={styles.description}>
                <p>
                    I will be providing educational content on this section in the future to help you learn more about the technologies I use and how to use them.
                    I want to educate business owners on what software engineers do and what the myths are surrounding developers. The biggest myth is that developers
                    are not needed because "anyone" nowadays can build a website. This is not true. Anyone can build a website, but not everyone can build a website that
                    performs great and is super sexy. If you are serious about your business, you will want to hire a professional to build your online business, not a weekend warrier 
                    that learned WordPress or WiX.
                </p>
            </div>
        </div>
    );
};

export default OurFounder;