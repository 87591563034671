import styles from './Founder.module.css';

const OurFounder: React.FC = () => {

  return (
    <div className={styles.ourFounder}>
      <div className={styles.header}>
        <h1>Our Founder</h1>
      </div>
      <div className={styles.description}>
        <p>
          I'm a software engineer, husband, and father. I love to learn and I love to teach. I'm passionate about building software that makes a difference in people's lives.
          Instead of you just reading a corporate looking site and hoping, upon our initial conversation to 'feel me out' and see if we'd be a good fit. So instead, I've chosen to write a
          little about who I am, why I do what I do, and what I could do for you, if you are indeed in need of my services. I started out messing around on an Apple II and MS DOS in the
          late 1980's, and haven't stopped since! I have just added a lot of languages like JavaScript, Python, C++, Swift, Java, etc., as well as the ability to build my code in a
          plethora of different frameworks and environments such as but not limited to: ReactJS, React Native, NextJS, AWS (Amazon Web Services), Oracle, and much more.
        </p>
      </div>
    </div>
  );
};

export default OurFounder;
