// Showcase.tsx component that displays all of our projects

import React from 'react';
import styles from './Showcase.module.css';

// Define the type for the loading prop to match the expected values
type LoadingType = "eager" | "lazy" | undefined;

// GridItem component
const GridItem: React.FC<{
  icon: string;
  title: string;
  description: string;
  url: string;
  loading?: LoadingType;
}> = ({ icon, title, description, url, loading = "eager" }) => (
  <a href={url} className={styles.gridItem} target="_blank" rel="noopener noreferrer">
    <div className={styles.imageContainer}>
      <img src={icon} alt={title} className={styles.icon} loading={loading} />
    </div>
    <div className={styles.titleAndDescription}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
  </a>
);


const Showcase: React.FC = () => {
  return (
    <div className={styles.showcase}>
      <h1>Showcase</h1>
      <div className={styles.gridContainer}>
        <GridItem
          icon="/images/audiopro_icon.avif"
          title="AudioPro"
          description="AudioPro is a revolutionary piece of technology..."
          url="https://www.audiopro.cloud"
          loading="lazy"
        />
        <GridItem
          icon="/images/solairus_icon.avif"
          title="Solairus Aviation"
          description="A private jet charter app on iOS and Android for chartering your next private plane."
          url="https://www.solairus.aero"
          loading="lazy"
        />
        <GridItem
          icon="/images/columbia_woodlands_icon.avif"
          title="Columbia Woodlands"
          description="A private glamping destination that takes camping in style to a whole other level!"
          url="https://www.columbiawoodlands.com"
          loading="lazy"
        />
        <GridItem
          icon="/images/surge_staffing_icon.avif"
          title="Surge Staffing"
          description="A nationwide staffing agency that we created a reporting dashboard for their many locations to quickly view their statistics, saving them time and money."
          url="https://www.surgestaffing.com"
          loading="lazy"
        />
        <GridItem
          icon="/images/mark_and_troy_icon.avif"
          title="Mark Campbell & Troy Evans"
          description="Worked on various project for the two former NFL players."
          url="https://www.medkinect.com"
          loading="lazy"
        />
        <GridItem
          icon="/images/ziering_icon.avif"
          title="Dr. Craig Ziering"
          description="World-renowned hair transplant surgeon, Dr. Craig Ziering, needed a website to showcase his work and we delivered much more. An Ai powered prescription refill system."
          url="https://www.zieringmedical.com"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Showcase;
