/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styles from './Hero.module.css';

const Hero = () => {
  // Define the dimensions for the profile picture
  const profilePicWidth = 350; // width in pixels
  const profilePicHeight = 350; // height in pixels

  return (
    <div className={styles.hero}>
      <div className={styles.imageAndTitleColumn}>
        <img
          src='/images/james_profile_pic.avif'
          className={styles.jamesImage}
          alt="Profile picture of James New, Owner and Programmer of GCSD"
          width={profilePicWidth}
          height={profilePicHeight}
        />
        <h1 className={styles.myName}>James L New</h1>
        <h2 className={styles.myTitle}>Full Stack Software Developer</h2>
      </div>
      <div className={styles.heroTextColumn}>
        <h2 className={styles.heroTitle}>About Me</h2>
        <p className={styles.heroDescription}>I am a full stack software developer with a passion for learning and creating. I am a self-taught coder from the 90's, that went on to get my formal Computer Science degree from the University of Colorado. I
          have worked for Fortune 500's, start-ups, celebrities, professional athletes, doctors with HIPAA compliancy migrations to the cloud, and many more. I have worked on projects ranging from simple websites to complex enterprise applications. I
          have worked with many different languages and frameworks, but my current favorites are ReactJS, NodeJS, and AWS. I have a passion for learning and creating and I am always looking for new challenges.</p>
        <h2 className={styles.heroTitleTwo}>This Site</h2>
        <p className={styles.heroDescriptionTwo}>This site is proudly built in ReactJS, on the AWS cloud utilizing all micro-services and lambda
          functions. I am also taking advantage of the AWS CDK to build out the infrastructure as code. I am just
          waiting for the next client that wants quality, speed, and security out of their app
          and developer. I am ready to build your next app!
        </p>
      </div>
    </div>
  );
};

export default Hero;
