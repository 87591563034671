import React, { useState, useEffect, useRef } from 'react';
import styles from './BottomDrawer.module.css';
import Showcase from '../Showcase/Showcase';
import Founder from '../OurFounder/Founder';
import Education from '../Education/Education';
import Contact from '../Contact/Contact';

interface BottomDrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({ isOpen, setIsOpen, activeTab, setActiveTab }: BottomDrawerProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  const handleTabClick = (tab: string) => {
    if (isOpen && activeTab === tab) {
      setIsOpen(false);
    } else {
      setActiveTab(tab);
      setIsOpen(true);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'showcase':
        return <Showcase />;
      case 'founder':
        return <Founder />;
      case 'education':
        return <Education />;
      case 'contact':
        return <Contact />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`${styles.bottomDrawer} ${isOpen ? styles.open : ''}`}
      ref={drawerRef}
    >
      <div className={styles.tabs}>
        <button
          onClick={() => handleTabClick('showcase')}
          className={`${styles.button} ${activeTab === 'showcase' ? styles.active : ''}`}
        >
          Showcase
        </button>
        <button
          onClick={() => handleTabClick('founder')}
          className={`${styles.button} ${activeTab === 'founder' ? styles.active : ''}`}
        >
          Our Founder
        </button>
        <button
          onClick={() => handleTabClick('education')}
          className={`${styles.button} ${activeTab === 'education' ? styles.active : ''}`}
        >
          Education
        </button>
        <button
          onClick={() => handleTabClick('contact')}
          className={`${styles.button} ${activeTab === 'contact' ? styles.active : ''}`}
        >
          Contact Us
        </button>
      </div>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};

export default BottomDrawer;
