import React, { useState } from 'react';
import styles from './Contact.module.css';
import { sendContactForm } from '../../utils/aws-contactMeSESemailer';

const Contact: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Validate form fields
        if (!name || !email || !message) {
            console.log('Please fill in all fields');
            return;
        }

        // Send contact form
        try {
            await sendContactForm({ name, email, message });
            console.log('Contact form submitted successfully');
            // Reset form fields
            setName('');
            setEmail('');
            setMessage('');
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting contact form:', error);
        }
    };

    const handleResetForm = () => {
        setName('');
        setEmail('');
        setMessage('');
        setIsSubmitted(false);
    };

    return (
        <div className={styles.contact}>
            <h1>Contact Us</h1>
            <div className={styles.contactContainer}>
                {isSubmitted ? (
                    <div>
                        <p className={styles.successMessage}>Email successfully sent!</p>
                        <button type="button" className={styles.closeButton} onClick={handleResetForm}>
                            Close
                        </button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.formGroup}>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                rows={4}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <div className={styles.buttonContainer}>
                            <button type="submit" className={styles.submitButton}>
                                Submit
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default Contact;
