import { useState } from 'react';
import './App.css';
import awsConfig from './aws-exports';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

// Amplify
import { Amplify } from 'aws-amplify';
import './App.css';
import BottomDrawer from './components/BottomDrawer/BottomDrawer';
import Hero from './components/Hero/Hero';

Amplify.configure(awsConfig);

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('showcase');

  return (
    <div className="container">
      <Header />
      <main className="main">
        <Hero />
        <BottomDrawer
          children={undefined}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Footer />
      </main>
    </div>
  );
};

export default App;
